@import '../../css/';

.error-page {
  .title-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 15%;
    .code {
      font-family: 'SegoeSemiBold';
      font-size: 78px;
      color: #2b478b;
      line-height: 94px;
    }

    .subtitle {
      font-size: 18px;
      color: #2b478b;
    }
  }

  .bottom-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20%;
    .alert-not-found {
      font-family: 'SegoeSemiBold';
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: black;
    }
    .msg-not-found-wrapper {
      display: flex;
      text-align: center;
      justify-content: center;
      margin: 1rem 0;
      .message-not-found {
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: black;
        width: 60%;
      }
    }
  }
  .error-img {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
