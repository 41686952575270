@import '../../css/index.scss';

.add-sales-details {
    margin: 20px;
    .add-details-header-container {
        margin: 20px 0;
        .add-details-header {
            @extend .heading-3;
        }
    }
    .add-details-button-container {
        .button-container {
            margin: 0 20px 0 0;
        }
    }
    .sale-details-form-container {
        padding: 20px 0;
        .nozzle-reading-header {
            @extend .heading-4;
            margin: 0 0 20px 0;
        }
        .cash-header {
            @extend .heading-4;
            text-align: center;
            margin: 0 0 20px 0;
        }
        .cash-denomination {
            @extend .heading-5;
            margin: 0 20px 0 0;
            min-width: 100px;
            text-align: right;
        }
        .payment-details-container {
            margin: 30px 0;
            .payment-details {
                @extend .heading-4;
                margin: 0 20px 0 0;
                min-width: 100px;
            }
        }
        .credit-sales-header-container {
            margin: 0 0 20px 0;
            .credit-sale-title {
                @extend .heading-4;
                margin: 0 20px 0 0;
            }
        }
        .cash-total-container {
            margin: 20px 0;
            .cash-total-title {
               @extend .body-text-1-bold;
               font-size: 20px;
               margin: 0 10px 0 0;
            }
            .cash-total-amount {
                @extend .body-text-1-bold;
                font-size: 24px;
                color: $kushGreen-primary;
            }
        }
        .expense-header-container {
            margin: 20px 0;
            .expense-header {
                @extend .heading-4;
                margin: 0 20px 0 0;
            }
        }
        .expense-list-title {
            @extend .heading-5;
            margin: 20px 0 0 0;
        }

        .test-form-container {
            margin: 0 0 20px 0;
        }
    }
}

.total-modal-content {
    background-color: white;
    .total-modal-container {
        margin: 20px;
        .total-container {
            margin: 10px;
            .total-title {
                @extend .heading-4;
                margin: 0 20px 0 0;
            }
            .total-amount {
                @extend .heading-4;
            }
            .redded {
                color: $indianChilliRed-shade;
            }
            .greened {
                color: $kushGreen-shade;
            }
        }
    }
}

.credit-sales-modal {
    background: white;
    min-height: 400px;
    border-radius: 5px;
    width: max-content;
    .credit-sales-container {
        // margin: 20px;
        .header-container {
            padding: 20px;
            background-color: $kushGreen-shade; 
            border-radius: 5px 5px 0 0;
            .credit-sale-header {
                letter-spacing: 0.0025em;
                font-weight: 600;
                color: $cloud;
                @extend .heading-4;
            }
        }
        .credit-sale-form {
            padding: 20px;
            .select-customer-container {
                .select-customer-dropdown {
                    margin: 20px 0px;
                }
            }
           
        }
        .save-sales-container  {
            margin: 20px;
            .reset-button {
                margin: 0 20px 0 0;
            }
        }
        
    }
}