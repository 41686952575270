
.amount-card-container {
    width: 300px;
    height: 104px;
    left: 20px;
    top: 146px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #C5FCD8;
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 8px 16px -2px rgba(23, 143, 75, 0.12);
    border-radius: 20px;
}

.name-container {
    width: 82px;
    height: 18px;
    left: 135px;
    top: 32px;
    font-family: 'SourceSanPro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height */
    text-align: center;
    text-transform: capitalize;

    /* Default/Black */

    color: #000000;

    opacity: 0.6;
}

.value-container {
    width: 206px;
    height: 48px;
    left: 72px;
    top: 54px;
    font-family: 'SourceSanPro';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 48px;
    /* identical to box height, or 109% */

    text-align: center;
    text-transform: capitalize;

    /* Default/Black */

    color: #000000;
}


.amount-card-container-small{
    width: 200px;
    height: 104px;
    left: 20px;
    top: 146px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #C5FCD8;
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 8px 16px -2px rgba(23, 143, 75, 0.12);
    border-radius: 20px;
    .name-container {
        width: 82px;
        height: 18px;
        left: 135px;
        top: 32px;
        font-family: 'SourceSanPro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        /* identical to box height */
        text-align: center;
        text-transform: capitalize;
    
        /* Default/Black */
    
        color: #000000;
    
        opacity: 0.6;
    }
    
    .value-container {
        width: 180px;
        height: 48px;
        left: 72px;
        top: 54px;
        font-family: 'SourceSanPro';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 32px;
        /* identical to box height, or 109% */
    
        text-align: center;
        text-transform: capitalize;
    
        /* Default/Black */
    
        color: #000000;
    }
}