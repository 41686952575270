@import '../../../css/index.scss';
$desktop-width: 1024px;
.customer-details-container {
    display: flex;
    height: calc(100vh - 92px);
    .customer-list {
        flex-basis: 25%;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
        .customers-list-header-container {
            margin: 20px 0 30px 10px;
            .customers-list-header {
                @extend .heading-4;
                @extend .text-bold;
            }
        }
        .customer-list-table {
            overflow: auto;
            .customer-table {
                border-radius: 5px;
                width: 100%;
                .MuiTablePagination-selectLabel {
                    display: none;
                }
                .MuiTablePagination-select {
                    display: none;
                }
                .MuiSelect-icon {
                    display: none;
                }
                .MuiToolbar-root {
                    padding: 2px 0;
                }
            }
            .customer-table-head {
                font-weight: 600;
            }
            .customer-list-table-row {
                cursor: pointer;
                &:hover {
                    transform: scale(1.005);
                }
    
            }
            .customer-list-table-cell-center {
                text-align: center;
            }
        }
        
    }
    .customer-details {
        height: calc(100vh - 92px);
        flex-basis: 75%;
        border-left: 0.5px solid rgba($color: #1976d2, $alpha: 0.4);
    }
}

.customer-details-page-container {
    .customer-page-header-container {
        margin: 20px;
        .customer-page-name {
            margin: 20px 20px 10px 20px;
            @extend .heading-3;
            height: 35px;
            overflow: hidden;
            max-width: 600px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .customer-buttons {
            margin: 0 10px;
        }
    }
    .details-content-container {
        margin: 40px;

        .contact-container {
            margin: 20px 0;
            .contact-details {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .contact-title {
                    font-weight: 600;
                    min-width: 200px;
                }
                .contact-content {
                    text-align: left;
                }

            }
        }
        
        .customer-vehicle-list {
            .vehicles-header {
                @extend .heading-5;
                font-weight: 600;
                margin: 10px 0;
            }
        }
        .customer-info-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 10px 0;
            .contact-title {
                min-width: 200px;
                font-weight: 600;
            } 
            .contact-content {
                text-align: left;
            }
        }
    }

    .customer-transaction-container {
        .transaction-selector-container {
            .customer-transactions-tab-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 0.5px solid #1976d2;
                margin: 0 20px 0 0;
                .item {
                //   padding: 0px 16px 0px 24px;
                  width: 150px;
                }
              }
              .tab-container {
                display: flex;
                margin: 0 0 0 20px;
              }
              .tab-item {
                @extend .heading-5;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                height: 56px;
                color: rgba($squidInk-primary, 0.8);
                background-color: rgba($color: #1976d2, $alpha: 0.1);
                border-radius: 10px 10px 0 0;
        
              }
              .active-tab {
                letter-spacing: 0.001em;  
                color: $squidInk-primary;
                background-color: rgba($color: #1976d2, $alpha: 0.2);
                .select-marker-tab {
                    
                  height: 4px;
                  background: #1976d2;
                  width: 100%;
                  position: absolute;
                  bottom: 0;
                  border-top-left-radius: 4px;
                  border-top-right-radius: 4px;
                }
              }
        }
        .customer-transaction-table-container {
            margin: 20px;
            max-height: 300px;
            overflow: auto;
        }
    }    
}


@media only screen and (max-width: 1024px) {
    .customer-details-container {
        .customer-list {
            .customers-list-header-container {
                .customers-list-header {
                    font-family: 'SourceSanPro';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 25px;
                    line-height: 20px;
                    letter-spacing: 0em;
                    color: $squidInk-shade;
                }
            }
            .customer-list-table {
                .customer-list-table-row {
                    cursor: pointer;
                    &:hover {
                        transform: scale(1.005);
                    }
                }
                .is-Desktop-only {
                    display: none;
                }
                .customer-table {
                        .MuiTablePagination-toolbar {
                            display: inline;
                            .MuiTablePagination-displayedRows {
                                display: none;
                            }
                        }
                }
            }
        }

    }


  }



  .add-payment-modal {
    background: white;
    min-height: 350px;
    border-radius: 5px;
    min-width: max-content;
    .add-payments-container {
        // margin: 20px;
        .header-container {
            padding: 20px;
            background-color: $kushGreen-shade; 
            border-radius: 5px 5px 0 0;
            .add-payment-header {
                letter-spacing: 0.0025em;
                font-weight: 600;
                color: $cloud;
                @extend .heading-4;
            }
        }
        .add-payment-form {
            padding: 20px;
                .customer-name-header {
                    @extend .heading-5;
                    @extend .text-bold;
                    padding: 10px;
                }
                .payment-mode-input {
                    padding: 12px 0;
                }
                .payment-add-icon {
                    min-width: 62px;
                }
                .payments-table-container {
                    max-height: 250px;
                    overflow: auto;
                }
        }
        .save-sales-container  {
            margin: 20px;
            .reset-button {
                margin: 0 20px 0 0;
            }
        }
        
    }
}

[role=dialog] {
    z-index: 99999999 !important;
}


.payments-credit-modal-container {
    background: white;
    min-height: 400px;
    border-radius: 5px;
    width: fit-content;
    padding: 10px;
    .credits-table {
        padding: 5px;
    }
}

.statement-modal-container {
    background: white;
    min-height: 350px;
    border-radius: 5px;
    min-width: max-content;
    max-height: calc(100vh - 60px);
    padding: 20px;
    .statement-header {
        padding: 20px;
        // background-color: $kushGreen-shade; 
        border-radius: 5px 5px 0 0;
        letter-spacing: 0.0025em;
            font-weight: 600;
            @extend .heading-4;
    }
    .account-statement-table-container {
        overflow-y: auto;
        height: calc(100vh - 200px);
    }

}