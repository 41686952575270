$primary-color: #1976d2;  // Bright blue
$secondary-color: #00c6ff; // Vibrant turquoise
$text-color: #ffffff;       // Clean white
$background-color: #f0f4f8; // Light grayish blue
$hover-bg-color: rgba(255, 255, 255, 0.1); // Hover background color with transparency

.expense-totals {
  margin: 30px auto;
  padding: 20px;
  border-radius: 12px;
  background: linear-gradient(135deg, $primary-color, $secondary-color);
  color: $text-color;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: 'SourceSanPro', sans-serif;

  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px;
    color: $text-color;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  .expense-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 5px;
    border-bottom: 1px solid rgba($text-color, 0.3);
    transition: all 0.3s ease, background-position 0.3ms;
    &:hover {
      background-color: $hover-bg-color;
      border-radius: 8px;
    }

    .expense-type, .expense-amount {
      font-weight: bold;
      color: $text-color;
    }

    .expense-amount {
      font-size: 18px;
    }

    .expense-type-total, .expense-amount-total {
        font-weight: bold;
        color: $text-color;
        font-size: 21px;
      }
  }

  .expense-item-total {
    display: flex;
    justify-content: flex-end;
    padding: 12px 5px;
    border-bottom: 1px solid rgba($text-color, 0.3);
    transition: all 0.3s ease, background-position 0.3ms;
    &:hover {
      background-color: $hover-bg-color;
      border-radius: 8px;
    }

    .expense-type-total, .expense-amount-total {
        font-weight: bold;
        color: $text-color;
        font-size: 18px;
        padding: 0 0 0 10px;
        font-family: 'SourceSanProBold', sans-serif;
      }
  }
}
