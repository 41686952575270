@import './colors', './grid', './typography', './table';     // import all the partials in index file.
@import "bulma/bulma.sass";

.loading-animation-container {
    margin: auto;
    position: absolute;
    inset: 0px;
    background-color: rgba(2, 17, 27, 0.42);
    width: 100vw;
    height: 100vh;
    z-index: 9999;
  }


  .animation-box {
    margin: auto;
    position: absolute;
    inset: 0px;
    width: 180px;
    height: 180px;
    border: 1px #1976d2 solid;
    border-radius: 50%;
    padding: 2px;
    background-color: rgba(255, 255, 255, 0.87);
    // background-repeat: no-repeat;
    // background-size: 0 2px, 0 100%, 0% 2px;
    // background-position: top center, top center, bottom center;
    animation: 2s steps(30) infinite fade-in 0.5s;
    align-items: center;
  }
  

  @keyframes fade-in {
    0% {
      opacity: 1;
      transform: rotateY(180deg);
    }
    25% {
        opacity: 1;
        transform: rotateY(135deg);
      }
    50% {
        opacity: 1;
        transform: rotateY(90deg);
      }
    75% {
        opacity: 1;
        transform: rotateY(45deg);
      }
    100% {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }