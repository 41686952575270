@import '../../css/index.scss';

.employee-page-container {
    margin: 30px;
    .employee-page-headers {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .employee-details-cards-container {
        margin: 30px 0;
    }
}


.employee-details-card {
    margin: 20px 0;
    min-height: 200px;
    border-radius: 15px;
    border: 1px solid black;
    box-shadow: 0px 8px 16px -2px rgba(23, 143, 75, 0.12);
    .details-header-container {
        padding: 30px 30px 0 30px;
        margin:0 0 10px 0;
        .name-container {
            display: flex;
            // justify-content: center;
            align-items: center;
            height: 90px;
            .employee-icon-container {
                width: 80px;
                margin: 0 30px 0 0;
                padding: 10px;
                border-radius: 5px;
                border: 0.5px solid grey;
                .employee-icon{
                    width: 60px;
                }
                
            }
            .employee-name {
                font-style: normal;
                font-size: 48px;
                line-height: 22px;
                letter-spacing: 0em;
                color: $squidInk-primary !important;
            }

        }
    }
    .total-amount-container  {
        height: 90px;
        text-align: right;
        margin: auto 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .balance-container-header {
            margin: 0 20px 0 0;
            height: 100%;
            display: table;
            p {
                display: table-cell;
                vertical-align: bottom;
                padding: 0 0 14px 0;
            }
        }
        
    }

    .attendance-details-container {
        padding: 30px 30px 0 30px;
        .attendance-details-header {
            margin: 10px 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .attendance-details {
                min-width: 220px;
            }
        }
        .attendance-details-header-right {
            margin: 10px 0;
            display: flex;
            justify-content: flex-end;
            .attendance-content {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 300px;
                .attendance-details {
                    min-width: 220px;
                }
            }
           
        }
    }

    .employee-records-container {
        padding: 30px 30px 10px 30px;

    }

    .card-accordian-arrow-container {
        height: 40px;
        display: flex;
        justify-content: center;
        border-radius: 0 0 15px 15px;
        .arrow-container {
            .down-arrow-icon {
                width: 30px;
            }
            .rotate-arrow {
                transform: rotate(180deg);
            }
        }
    }
    .card-accordian-arrow-container:hover {
        background-color: rgba(246, 246, 246, 0.8);
        .arrow-container {
            .down-arrow-icon {
                transform: translateY(5px);
            }
        }
        
    }
}