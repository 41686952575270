@import '../../../css/';


.dashboard-container {
    min-height: 100vh;
    padding: 20px;
    background-color: #E0E9F5;
    max-width: 100%;
    .date-picker-con{
        position: sticky;        
        top: 64px;
        width: 100%;
    }
    .total-sales-container {
        margin: 20px 0;
        width: 100%;
        .sales-container {
            
        }
        .total-sales-heading {
            @extend .heading-3;
            margin: 20px 0;
        }
        .settlement-details {
            margin: 10px 0;
            @extend .heading-4;
            gap: 5px;
            .settle-header {
                min-width: 200px;
                font-weight: 700;
            }
        }
    }
    .employee-sales-container {
        margin: 40px 0;
        .total-sales-heading {
            @extend .heading-3;
            margin: 20px 0;
        }
        .sales-table {
            border-radius: 5px;
        }
    }
    .credit-sales-container {
        margin: 40px 0;
        .total-sales-heading {
            @extend .heading-3;
            margin: 20px 0;
        }
        .total-amounts {
            @extend .heading-4;
        }
        .sales-table {
            border-radius: 5px;
        }
    }
    .payments-sales-container {
        margin: 40px 0;

        .total-sales-heading {
            @extend .heading-4;
            margin: 20px 0;
        }
        .total-amounts {
            @extend .heading-4;
        }
        .sales-table {
            border-radius: 5px;
        }
    }
    .no-data-container {
        height: 400px;
    }
}

.add-readings-modal {
    background: white;
    min-height: 220px;
    border-radius: 5px;
    width: max-content;
    .add-readings-container {
        // margin: 20px;
        .header-container {
            padding: 20px;
            background-color: $kushGreen-shade; 
            border-radius: 5px 5px 0 0;
            .credit-sale-header {
                letter-spacing: 0.0025em;
                font-weight: 600;
                color: $cloud;
                @extend .heading-4;
            }
        }
        .add-readings-form {
            padding: 20px;
            .select-customer-container {
                .select-customer-dropdown {
                    margin: 20px 0px;
                }
            }
           
        }
        .save-readings-container  {
            margin: 20px;
            .reset-button {
                margin: 0 20px 0 0;
            }
        }
        
    }
} 

.readings-modal-content {
    background-color: white;
    .readings-modal-container {
        margin: 20px;
        .readings-container {
            margin: 10px;
            .readings-title {
                @extend .heading-4;
                margin: 0 20px 0 0;
            }
            .readings-amount {
                @extend .heading-4;
            }
        }
    }
}

.cashflow-modal-content {
    background-color: white;
    border-radius: 5px;
    .cashflow-modal-container {
        margin: 20px;
        .cashflow-header {
            margin: 10px 10px 40px 10px;
            @extend .heading-3;
            @extend .text-bold;
            text-align: center;
        }
        .readings-container {
            margin: 10px;
            .readings-title {
                @extend .heading-4;
                margin: 0 20px 0 0;
                min-width: 260px;
                text-align: right;
            }
            .readings-amount {
                @extend .heading-4;
                font-weight: 600;
            }
            .redded {
                color: $indianChilliRed-shade;
            }
            .greened {
                color: $kushGreen-shade;
            }
        }
    }
}

