@font-face {
    font-family: 'SourceSanPro';
    src: url(./fonts/SourceSansPro-Regular.ttf);
  }

  @font-face {
    font-family: 'SourceSanProBold';
    src: url(./fonts/SourceSansPro-Bold.ttf);
  }

  @font-face {
    font-family: 'IBMPlexRegular';
    src: url(./fonts/IBMPlexSans-Regular.ttf);
  }