@import '../../css/index.scss';

.customers-list-container {
    margin: 10px;
    height: calc(100vh - 92px);
    .customers-list-header-container {
        margin: 20px 0;
        .customers-list-header {
            @extend .heading-3;
        }
    }
}
.customer-outstanding {
    @extend .heading-5;
    // width: 900px;
    margin: 10px 0;
    // font-size: 20px;
}

