@import '../../css/';
@media screen and (max-width: 600px) {
  .title-wrapper {
    top: 10% !important;
  }
  .bottom-wrapper {
    bottom: unset !important;
    top: 40% !important;
  }
  .error-img {
    bottom: 20% !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1280px) and (min-height: 700px) {
  .title-wrapper {
    top: 10% !important;
  }
  .bottom-wrapper {
    bottom: unset !important;
    top: 40% !important;
  }
  .error-img {
    bottom: 0 !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1280px) and (max-height: 700px) {
  .bottom-wrapper {
    top: unset !important;
    bottom: 10% !important;
  }
}
