@import '../../../css/index.scss';

.expenses-page-container {
    margin: 30px;
    .expenses-page-headers {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .expenses-details-cards-container {
        margin: 30px 0;
    }
}
.expenses-list-container {
    margin: 30px 0;
    .expenses-table {
        border-radius: 5px;
    }
}
.expense-selector {
    display: flex;
    .add-expense-button {
        margin: 10px;
    }
}