.list-table-wrapper {
    padding: 0px 24px 24px 24px;
    overflow-x: auto;
    .list-table {
      border-collapse: collapse;
      width: 100%;
      .link {
        padding: 0;
      }
  
      .list-table-header {
        height: 48px;
        background: rgba($squidInk-shade, 0.75);
        tr {
          height: 48px;
          th {
            padding: 0 0 0 24px;
            text-align: left;
            @extend .body-text;
            color: $vanilla;
          }
          th:first-child {
            border-radius: 6px 0 0 0;
          }
          th:last-child {
            border-radius: 0 6px 0 0;
          }
        }
      }
  
      tbody {
        border: 1px solid $rainCloud;
        tr {
          height: 40px;
          background: $cloud;
          td {
            padding: 0 0 0 24px;
            text-align: left;
            @extend .body-text;
            color: $squidInk-primary;
            &.empty-table {
              text-align: center;
            }
          }
        }
        tr:nth-child(even) {
          background: rgba($thunderCloud, 0.16);
        }
      }
    }
  
    //   th {
    //     @extend .body-text;
    //   }
    //   td {
    //     padding: 17px 0px 18px 24px;
    //     @extend .body-text;
    //   }
  
    //   tr:nth-child(even) {
    //     background: $vanilla;
    //   }
  }
  