@import '../../../css/index.scss';

.add-customer-modal {
    background-color: white;
    width: max-content;
    min-height: 400px;
    max-height: 600px;
    border-radius: 5px;
    .add-customer-modal-container {
        .add-customer-header {
            padding: 20px;
            background-color: $kushGreen-shade; 
            @extend .heading-4;
            letter-spacing: 0.0025em;
            font-weight: 600;
            color: $cloud;
            border-radius: 5px 5px 0 0;
        }
        .add-customer-form-container {
            overflow-y: auto;
            max-height: 500px;
            .add-customer-form {
                padding: 20px 25px;
                
                .customer-input-fields {
                    padding: 10px 0;
                }
    
                .vehicles-list-container {
                    margin: 20px 0;
                    .add-vehicle-title {
                        @extend .heading-5;
                        margin: 20px 0 15px 0;
                    }
                }
            }

            .submit-reset-button-container {
                margin: 20px 0 0 0;
                .reset-button {
                    margin: 0 10px 0 0;
                }
            }
        }
        
    }
}

.sucess-alert {
    z-index: 9999;
}