@import '../../css/index.scss';


.login-content-container {
    @include desktop {
        margin: 100px 0 0 0;
      }
    .login-content-layout {
        width: 300px;
        .login-image {
            margin: 0 0 70px 0;
        } 
        .login-form {
            background-color: white;
            padding-left: 30px;
            padding-top: 20px;
              margin-top: -60px;
              margin-left:-30px;
              margin-right:-30px;
              border-radius: 8px;
              border: 1px solid #d8d8da;
            .login-form-container {
                width: 300px;
                margin: 90px 0 0 0;
                .login-input {
                    margin: 30px 0;
                }
                .login-button {
                    margin: 20px 0;
                }
            }
        }  
    }
}