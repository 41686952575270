@import '../../css/';

.date-picker-container {
    padding: 30px;
    background-color: #EBF4FF;
    border-radius: 5px;

    .center-container {
        .show-selected-date-details {
            text-align: center;
            min-width: 390px;
            margin: 0 30px;
            @extend .heading-3;
        }
        .show-selected-date-details-tab {
            text-align: center;
            min-width: 100px;
            margin: 0 15px;
            @extend .heading-4;
        }
        .go-left-arrow {
            margin: 4px 0 0 0;
        }
        .go-right-arrow {
            margin: 4px 0 0 0;
        }
    }
}