@import './fonts', './colors';

.heading-0 {
  font-family: 'SourceSanPro';
  font-style: normal;
  font-weight: normal;
  font-size: 80px;
  line-height: 22px;
  letter-spacing: -0.0015em;
  color: $squidInk-shade;
}

.heading-1 {
  font-family: 'SourceSanPro';
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 22px;
  letter-spacing: -0.005em;
  color: $squidInk-shade;
}

.heading-2 {
  font-family: 'SourceSanPro';
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 22px;
  letter-spacing: 0em;
  color: $squidInk-shade;
}

.heading-3 {
  font-family: 'SourceSanPro';
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: $squidInk-shade;
}

.heading-4 {
  font-family: 'SourceSanPro';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: 0em;
  color: $squidInk-shade;
}

.heading-5 {
  font-family: 'SourceSanPro';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0em;
  color: $squidInk-shade;
}

.heading-3-alert {
  @extend .heading-3;
  font-weight: bold;
}


.body-text {
  font-family: 'SourceSanPro';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: $squidInk-shade;
  .link {
    text-decoration-line: underline;
    color: $pumpkin-primary;
    opacity: 0.8;
  }
}

.body-text-1 {
  @extend .body-text;
  font-size: 16px;
}

.body-text-1-bold {
  @extend .body-text;
  font-family: 'SourceSanProBold';
  font-size: 16px;
}

.body-text-3 {
  @extend .body-text;
  font-size: 18px;
}

.body-text-bold {
  @extend .body-text;
  font-family: 'SourceSanProBold';
}

.body-text-2 {
  font-family: 'SourceSanPro';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: 0.0025em;
  color: $squidInk-shade;
}

.caption-12 {
  font-family: 'SourceSanPro';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: $squidInk-shade;
}


.caption-11-ibmPlex {
  font-family: 'IBMPlexRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 130%;
  letter-spacing: 0.015em;
  color: $squidInk-shade;
}

.text-bold {
  font-family: 'SourceSanProBold';
}


