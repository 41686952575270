@import '../../css/index.scss';



.custom-table-container {
    .table-comp {
        border-radius: 5px;
        width: 100%;
    }
    .table-head {
        // position: sticky;        
        // top: 0;
    }
    .clickable-table-row {
        cursor: pointer;
        &:hover {
            transform: scale(1.005);
        }
    }
}
