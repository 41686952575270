@import '../../css/index.scss';

.save-records-container {
    margin: 20px;
    height: 100%;
    .headers-save {
        margin: 0 30px;
        text-align: center;
    }
}

.save-recods-options {
    margin: 20px;

    .options-container {
        margin: 30px;
    }
}


.options-button {
    width: 200px;
    height: 200px;
    border: 1px solid black;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
    .option-name {
        @extend .heading-5;
        max-width: 80px;
        text-align: center;
        font-weight: 800;
    }
    
}

.options-button:hover {
    transform: scale(1.1);
}


.add-attendance-modal {
    background: white;
    min-height: 270px;
    border-radius: 5px;
    // width: max-content;
    height: fit-content;
    .add-attendance-container {
        // margin: 20px;
        .header-container {
            padding: 20px;
            background-color: $kushGreen-shade; 
            border-radius: 5px 5px 0 0;
            .attendance-header {
                letter-spacing: 0.0025em;
                font-weight: 600;
                color: $cloud;
                @extend .heading-4;
            }
        }
        .add-attendance-form {
            padding: 20px;
            .select-customer-container {
                .select-customer-dropdown {
                    margin: 20px 0px;
                }
            }
            .attendance-table-container {
                max-height: 250px;
                overflow: auto;
            }
        }
        .save-attendance-container  {
            margin: 20px;
            .reset-button {
                margin: 0 20px 0 0;
            }
        }
        
    }
} 