@import '../../../css/index.scss';


.expenses-page-container {
    margin: 30px;
    .expenses-page-headers {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .expenses-details-cards-container {
        margin: 30px 0;
    }
}

.financial-table {
    margin: 30px 0 0;
    display: flex;
    // gap: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px 8px 0 0;
    // padding: 20px;
    background-color: #ffffff;
    font-family: 'SourceSanPro', sans-serif;
    .section-container {
      flex: 1;
    border-right: 1px solid #e0e0e0;
      .section-title {
        // font-family: 'SourceSanProBold', sans-serif;
        font-weight: bold;
        font-size: 20px;
        font-style: italic;
        margin-bottom: 10px;
        border-bottom: 1px solid #e0e0e0;
        padding: 15px;

      }
  
      .table {
        display: flex;
        flex-direction: column;
      }
  
      .table-row {
        display: grid;
        height: 35px;
        grid-template-columns: 2fr 2fr 1fr;
        padding: 5px 15px;
        // border-bottom: 1px solid #e0e0e0;
  
        &:last-child {
          border-bottom: none;
        }
  
        .table-cell {
          padding: 0 5px;
          font-size: 14px;
  
          &.text-right {
            text-align: right;
            color: #007bff; /* Blue color for amounts */
          }
  
          &.bold {
            font-family: 'SourceSanProBold', sans-serif;
            font-size: 16px;
            margin: 5px 0;
          }
        }
  
        &.section-subtotal {
        //   background-color: #f9f9f9;
        //   margin-top: 10px;
          padding: 5px 15px;
          height: 40px;
           border-bottom: 1px solid #e0e0e0;
        }
  
        &.net-profit-loss {
          background-color: #f1f1f1;
          margin: 30px 20px 10px 20px;
          padding: 0px 10px;
          border-radius: 5px;
        }

      }
    }
    .income {
        border-right: none;
    }
  }

  .totals-table {
    display: flex;
    // gap: 20px;
    border-top: none;
    border-left: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-radius: 0 0 8px 8px;
    // padding: 20px;
    background-color: #ffffff;
    font-family: 'SourceSanPro', sans-serif;
    .section-container {
      flex: 1;
      border-right: 1px solid #e0e0e0;
      .section-title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 10px;
      }
  
      .table {
        display: flex;
        flex-direction: column;
      }
  
      .table-row {
        display: grid;
        height: 40px;
        grid-template-columns: 2fr 2fr 1fr;
        &:last-child {
          border-bottom: none;
        }
  
        .table-cell {
          padding: 5px;
          font-size: 14px;
            font-style: italic;
          &.text-right {
            text-align: right;
            color: #007bff; /* Blue color for amounts */
          }
  
          &.bold {
            font-weight: bold;
            font-size: 16px;
          }
        }
        &.total-row {

          padding: 5px 15px;
        }
      }
    }
    .income {
        border-right: none;
    }
  }
  