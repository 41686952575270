@import '../../css/index.scss';

.sales-list-container {
    margin: 10px;
    height: calc(100vh - 92px);
    .sales-list-header-container {
        margin: 20px 0;
        .sales-list-header {
            @extend .heading-3;
        }
    }
}

