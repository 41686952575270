@import '../../css/';
.drop-container {
  position: relative;
  font-size: 18px;
  // padding: 8px 0 8px 24px;
}
.drop {
  cursor: pointer;
}
.drop-list {
  position: absolute;
  width: max-content;
  min-width: inherit;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transform: scale(0);
  z-index: 999;
  background: $cloud;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  transform-origin: right top;
}
.drop-list::-webkit-scrollbar-track {
  border-radius: 8px;
}

.drop-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $dirtSnow;
  border-right: 2px $cloud solid;
}
.drop-list::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  border-radius: 8px;
}

.drop-active {
  transform: scale(1);
}

.drop-inactive {
  transform: scale(0);
}

.list-item {
  @extend .body-text;
  padding: 0 16px !important;
  height: 48px;
  text-align: left;
  width: 100%;
  &:hover {
    background: $vanilla;
  }
}

.drop-list-max {
  height: max-content !important;
  // max-height: 200px;
}
