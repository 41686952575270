@import '../../css/index.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($squidInk-primary, 0.72);
  transition: opacity 0.2s ease;
  z-index: 999999;
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-in {
  display: flex;
}

.dashboard-modal-content {
  display: flex;
  width: 100%;
  margin: 88px 12px 24px 276px;
  height: calc(100% - 48px);
  height: -webkit-calc(100% - 48px);
  height: -moz-calc(100% - 48px);
  position: relative;
}

.dashboard-modal {
  justify-content: flex-start;
  align-items: flex-start;
}

.align-modal-content {
  align-items: center;
  justify-content: center;
}
